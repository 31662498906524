import React from "react";

import audioStyles from "./audio.module.css";

import { graphql } from "gatsby";
import Head from "../components/head";

export const query = graphql`
  query {
    allContentfulAudioUpload {
      edges {
        node {
          title
          description {
            description
          }
          audio {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

class Audio extends React.Component {
  componentDidMount() {
    console.log(this.props.data.allContentfulAudioUpload.edges.length);
    console.log(this.props.data.allContentfulAudioUpload.edges[0].node.title);
  }
  render() {
    return (
      <>
      <Head title="TSS audio"></Head>
        <div className={audioStyles.audioContainer}>
          <h1>Audio</h1>
          {this.props.data.allContentfulAudioUpload.edges.length === 1 &&
          this.props.data.allContentfulAudioUpload.edges[0].node.title ===
            "null" ? (
            <div className={audioStyles.audioPlayerContainer}>
              <h3>No Audio files uploaded yet.</h3>
            </div>
          ) : (
            this.props.data.allContentfulAudioUpload.edges.map((edge, index) => {
              if (edge.node.title !== "null") {
                return (
                  <div key={index} className={audioStyles.audioPlayerContainer}>
                    <h3>{edge.node.title}</h3>
                    <p>{edge.node.description.description}</p>
                    <audio controls>
                      <source src={edge.node.audio.file.url} type="audio/mp3" />
                    </audio>
                  </div>
                );
              }
            })
          )}
        </div>
      </>
    );
  }
}
export default Audio;
